<template>
  <div>
    <form-wizard
        color="#d23d24"
        :title="null"
        :subtitle="null"
        layout="vertical"
        finish-button-text="Zakończ trening"
        next-button-text="Następne"
        back-button-text="Poprzednie"
        class="mb-2"
        @on-complete="closeTraining"
    >
      <tab-content title="Oceń swój poziom energii" key="0" class="text-center"
         :before-change="()=>saveSubjectiveFeelings()">
        <h3>Oceń swój poziom energii przed treningiem</h3>
        <b-button-group>
          <b-button
              v-for="n in feelingRateCount"
              variant="outline-primary"
              @click="setFeelingRate(n)"
          >
            {{ n }}
          </b-button>
        </b-button-group>
      </tab-content>
      <tab-content
            v-for="(item, index) in lesson.training.atlasExercises"
            :key="index+1"
            :title="item.name"
            :before-change="()=>saveTraining(item)"
        >
          <b-row>
            <b-col
                cols="12"
                class="mb-2"
            >
              <h4 v-html="item.title" class="mb-1"></h4>
              <div v-html="item.description" class="mb-1"></div>
              <div>
                <b-form
                    ref="form"
                    :style="{height: trHeight}"
                    class="repeater-form mt-2"
                    @submit.prevent="repeateAgain">

                  <div class="mb-1" v-for="exerciseItem in item.atlasExercisesItems">
                    <h4 class="text-capitalize mb-1 mt-1 pt-1"
                        style="border-top: 1px solid #ddd;"
                        v-html="exerciseItem.additional_information !== item.title ? exerciseItem.additional_information : ''"></h4>
                    <b-table id="trainings" borderless :items="getSeries(exerciseItem)" :fields="fields">
                      <template #cell(repetitions)="data">
                        <b-form-input
                            type="text"
                            v-model="data.item.repetitions"
                            @change="saveRepetitions(exerciseItem, data.item)"
                        />
                      </template>
                      <template #cell(load)="data">
                        <b-form-input
                            type="text"
                            v-model="data.item.load"
                            @change="saveLoads(exerciseItem, data.item)"
                        />
                      </template>
                      <template #cell(index)="data">
                        <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0"
                            @click="removeItem(exerciseItem, item)"
                        >
                          <feather-icon
                              icon="XIcon"
                          />
                        </b-button>
                      </template>
                    </b-table>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-danger"
                        class="mt-1"
                        block
                        @click="repeateAgain(item, exerciseItem)"
                    >
                      <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                      />
                    </b-button>
                  </div>
                </b-form>

                <div class="float-right text-center" v-if="haveLastTraining">
                  <input
                      tabindex="-1"
                      type="button"
                      class="wizard-btn training-button"
                      @click="onShowTrainings"
                      value="Poprzednie treningi"
                  ><br>
                  <table v-if="showTrainings">
                    <tbody>
                    <tr>
                      <th v-text="trainingLabels.date"></th>
                      <th v-text="trainingLabels.feeling"></th>
                      <th v-text="trainingLabels.results"></th>
                    </tr>
                    <tr
                        style="border: 1px black solid;"
                        v-for="item in lesson.lessonTrainings"
                        :key="item.id"
                    >
                      <td>
                        {{ item.created_at.split(' ')[0] }}
                      </td>
                      <td v-text="item.feeling_rate"></td>
                      <td>
                        <table>
                          <tbody>
                          <tr class="small-table-text">
                            <th>
                              {{ fields[0].label }}
                            </th>
                            <th>
                              {{ fields[1].label }}
                            </th>
                            <th>
                              {{ fields[2].label }}
                            </th>
                          </tr>
                          <tr
                              v-for="n in item.seria"
                          >
                            <td v-text="n"></td>
                            <td>{{ JSON.parse(item.multi_repetitions)[n].value }}</td>
                            <td>{{ JSON.parse(item.multi_load)[n].value }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-col>
          </b-row>
        </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  BProgress, BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BCard, BFormSelect, BCardText, BButtonGroup, BTable
} from 'bootstrap-vue'
import {heightTransition} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {FormWizard, TabContent} from "vue-form-wizard";
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BProgress,
    BCardText,
    FormWizard,
    TabContent,
    BButtonGroup,
    BTable
  },
  directives: {
    Ripple,
  },
  props: ['course', 'refillable'],
  mixins: [heightTransition],
  data() {
    return {
      nextTodoId: 100000001,
      completedPercent: 40,
      fields: [
        {
          key: 'seria',
          label: '',
        },
        {
          key: 'repetitions',
          label: 'Powt.',
        },
        {
          key: 'load',
          label: 'Obci.',
        },
        {
          key: 'index',
          label: '',
        }
      ],
      feelingRate: '',
      feelingRateCount: 5,
      showTrainings: false,
      lastTrainings: [],
      trainingLabels: {
        'date': 'Data',
        'feeling': 'Energia',
        'results': 'Trening',
      }
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed: {
    lesson() {
      if( this.$store.getters['app/getCurrentCourse'].training.atlasExercises.length == 0){
        let atlasExercises = {
          atlasExercisesItems: [
            {
              id: 1,
              additional_information: '',
              seria: 1,
              load: '',
              repetitions: '',
            }
          ],
          description:"",
          id:1,
          title:"",
        };
        this.$store.getters['app/getCurrentCourse'].training.atlasExercises.push(atlasExercises);
      }

      if (typeof this.$store.getters['app/getCurrentCourse'] === 'undefined') return ''
      
      return this.$store.getters['app/getCurrentCourse']
    },
    haveLastTraining() {
      return this.lesson.lessonTrainings[0] && this.lesson.lessonTrainings[0].multi_repetitions.length > 0
    }
  },
  methods: {
    saveSubjectiveFeelings() {
      return true;
    },
    showAlert() {
      this.$bvToast.toast('Widzę, że nie jesteś w najlepszej formie - przejdź dziś przez plan bazując na minimalnej objętości.', {
        title: 'Oo uważaj..',
        variant: 'success',
      })
    },
    getSeries(exerciseItem) {
      let repetitions = '', load = '';
      const response = []
      let series = exerciseItem.seria

      if (series === '') {
        series = 1
      }

      for (let i = 1; i <= series; i += 1) {
        if('userLoad' in exerciseItem){
          load = exerciseItem.userLoad.hasOwnProperty([i]) ? exerciseItem.userLoad[i].value : '';
        }
        if('userRepetitions' in exerciseItem){
          repetitions = exerciseItem.userRepetitions.hasOwnProperty([i]) ? exerciseItem.userRepetitions.[i].value : '';
        }

        response.push({
          seria: i,
          repetitions: repetitions ? repetitions : exerciseItem.exerciseItem,
          load: load ? load : exerciseItem.load,
        })
      }
      
      return response
    },
    closeTraining() {
      const data = {
        lesson_id: this.$store.getters['app/getCurrentCourse'].id,
        is_done: true,
      }
      this.$http.post(`${this.$store.state.apiDomain}/api/lesson/toggle-lesson-done`, data).then(response => {
        this.$router.push({ name: 'my-courses' });
      })
    },
    saveTraining(item) {
       const data = {
        lesson_id: this.$store.getters['app/getCurrentCourse'].id,
        course_id: this.course.id,
        altas_exercise_id: item.id,
        feeling_rate: this.feelingRate,
        training_items: JSON.stringify(item.atlasExercisesItems),
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/lesson/set-lesson-trainings`, data).then(response => {
        if (response.status === 200) {
          this.$bvToast.toast('Trening został zapisany pomyślnie', {
            title: 'Zapis',
            variant: 'success',
          })
        } else {
          this.$bvToast.toast('Wystąpił błąd podczas zapisu treningu. Spróbuj ponownie.', {
            title: 'Zapis',
            variant: 'danger',
          })
        }
      })

      return true;
    },
    repeateAgain(item, exerciseItem) {
      exerciseItem.seria = parseInt(exerciseItem.seria) > 1 ? parseInt(exerciseItem.seria) + 1 : 2;
    },
    saveRepetitions(exerciseItem, item){
      //Saving user repetition input values
      if(exerciseItem.hasOwnProperty('userRepetitions')){
        return exerciseItem.userRepetitions.[item.seria] = {
          value: item.repetitions
        };
      }
      return exerciseItem.userRepetitions = {
        [item.seria]: {
          value: item.repetitions
        }
      };
    },
    saveLoads(exerciseItem, item){
      if(exerciseItem.hasOwnProperty('userLoad')){
        return exerciseItem.userLoad[item.seria] = {
          value: item.load
        };
      }
      return exerciseItem.userLoad = {
        [item.seria]: {
          value: item.load
        }
      };
    },
    removeItem(exerciseItem, item) {
      let index = item.atlasExercisesItems.indexOf(exerciseItem);

      return exerciseItem.seria != 1 ? exerciseItem.seria-- : item.atlasExercisesItems.splice(index, 1);
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    setFeelingRate(rate){
      this.feelingRate = rate;
      if (rate < 3) {
        this.showAlert();
      }
    },
    onShowTrainings() {
      this.showTrainings = !this.showTrainings;
    }
  },
}
</script>

<style lang="scss">
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.wizard-tab-content{
  width: 100%;
}
[dir] .vue-form-wizard .wizard-header{
  display: none;
}
[dir] .vue-form-wizard .wizard-tab-content{
  padding: 10px 0px;
}
[dir] #trainings {
  thead{
    th{
      background: #fff;
      text-align: center;
    }
  }
  td{
    padding: 3px 5px;
    .form-control{
      text-align: center;
    }
  }
}
.small-table-text {
  font-size: 10px;
}
.training-button {
  float: right;
  border-color: rgb(100, 100, 100)!important;
}
</style>
