<template>
  <div>
    <div class="progress-wrapper">
      <b-card-text class="mb-0">
        Ukończone: {{ curriculumItemCompletedPercent + '%' }}
      </b-card-text>
      <b-progress
          v-model="curriculumItemCompletedPercent"
          max="100"
      />
    </div>
    <form-wizard
        color="#d23d24"
        :title="null"
        :subtitle="null"
        layout="vertical"
        finish-button-text="Zapisz"
        next-button-text="Następne"
        back-button-text="Poprzednie"
        class="mb-3"
        @on-complete="submit"
    >
      <tab-content title="Oceń swój poziom energii" key="0">
        <h3>Oceń swój poziom energii przed treningiem</h3>
        <b-button-group>
          <b-button variant="outline-primary">
            1
          </b-button>
          <b-button variant="outline-primary">
            2
          </b-button>
          <b-button variant="outline-primary">
            3
          </b-button>
          <b-button variant="outline-primary">
            4
          </b-button>
          <b-button variant="outline-primary">
            5
          </b-button>
        </b-button-group>
      </tab-content>
      <div :key="group_idx" v-for="(group, group_idx) in curriculum.groups">
        <tab-content
            v-for="(item) in group.items"
            :key="item.id+1"
            :title="group.name"
            :before-change="saveStep"
        >
          <b-row>
            <b-col
                cols="12"
                class="mb-2"
            >
              <h3 v-html="group.name"></h3>
              <div v-html="item.content"></div>
              <hr/>
              <b-form-group
                  label="Ilość wykonanych powtórzeń"
                  label-for="number_of_repetitions_completed"
                  class="mb-2 mt-5"
              >
                <b-form-select
                    id="number_of_repetitions_completed"
                    v-model="item.number_of_repetitions_completed"
                    :options="number_of_repetitions"
                    trim
                />
              </b-form-group>
              <b-form-group
                  label="Z jakim obciążeniem"
                  label-for="load_completed"
                  class="mb-2 mt-2"
              >
                <b-form-select
                    id="load_completed"
                    v-model="item.load_completed"
                    :options="loads"
                    trim
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
      </div>
    </form-wizard>
  </div>
</template>

<script>
import { BProgress, BCardText, BFormSelect, BFormGroup, BRow, BCol, BButtonGroup, BButton } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BProgress,
    BCardText,
    BFormSelect,
    FormWizard,
    BFormGroup,
    TabContent,
    BRow,
    BCol,
    BButtonGroup,
    BButton
  },
  name: 'Curriculum',
  props: ['curriculum'],
  data() {
    return {
      current_step: 0,
      number_of_repetitions: ['5', '10', '15', '20', '25', '30', '35', '40', '45', '50'],
      loads: [
        '5', '10', '15', '20', '25', '30', '35', '40', '45', '50',
        '55', '60', '65', '70', '75', '80', '85', '90', '95', '100'
      ],
    }
  },
  computed: {
    curriculumItemCompleted() {
      let completedItems = 0;
      this.curriculum.groups.forEach(group => {
        completedItems += group.items.filter(function (item) {
          return item.checked;
        }).length;
      });

      return completedItems;
    },
    curriculumItems() {
      let items = 0;
      this.curriculum.groups.forEach(group => {
        items += group.items.length;
      });
      return items;
    },
    curriculumItemCompletedPercent() {
      return Math.round(this.curriculumItemCompleted / this.curriculumItems * 10000) / 100;
    }
  },
  methods: {
    saveStep() {
      console.log(this.curriculum);

      return true;
    },
    async submit(e) {
      // no refresh
      e.preventDefault()

      // data container
      const data = {
        curriculum: this.curriculum,
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/curriculumanswer/update`, data).then(response => {
        alert("Zmiany zostały zapisane");
      });
    },
  },
}
</script>

<style>
[type="checkbox"] {
  vertical-align: middle;
}

.vue-form-wizard ul.wizard-nav-pills {
  display: none;
}
</style>
