<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import plLocale from '@fullcalendar/core/locales/pl'
import { FormWizard, TabContent } from 'vue-form-wizard'
import {
  BRow, BCol, BCard, BCardText, BCollapse, BButton, BCardHeader, BCardBody, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'

// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import Quiz from './quiz/Quiz.vue'
// eslint-disable-next-line import/extensions
import Curriculum from './curriculum/Curriculum'
import Trainings from './ug2bs/Trainings'

export default {
  components: {
    BCard,
    BCardText,
    BFormCheckbox,
    BCollapse,
    BButton,
    BCardHeader,
    BCardBody,
    quillEditor,
    Quiz,
    Curriculum,
    BModal,
    FullCalendar,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    Trainings,
  },
  data() {
    return {
      course: {},
      newsletterAgreement: 0,
      newsletterState: null,
      currentEvent: {
        id: 0,
        title: '',
        content: '',
        date: '',
        is_done: false,
        info: {},
      },
      editorOption: {
        theme: 'snow',
        placeholder: 'Miejsce na Twoje notatki',
        modules: {
          toolbar: '#toolbar-container',
        },
      },
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,listMonth',
        },
        initialDate: '2022-02-01',
        fixedWeekCount: false,
        locale: plLocale,
        events: [],
        eventDisplay: 'block',
        eventColor: '#1facbb',
        eventClick: this.handleDateClick,
      },
      note: '',
      sections: [],
      showNextButton: true,
      showPrevButton: true,
    }
  },
  computed: {
    dummyLesson() {
      return {
        type: 'curriculum',
        title: 'curriculum 1',
        curriculum: {
          id: 1,
          name: 'curriculum 1',
          groups: [
            {
              id: 1,
              name: 'group 1',
              items: [
                {
                  id: 1,
                  content: 'item 1',
                  checked: true,
                },
                {
                  id: 2,
                  content: 'item 2',
                  checked: false,
                },
              ],
            },
            {
              id: 2,
              name: 'group 2',
              items: [
                {
                  id: 3,
                  content: 'item 3',
                  checked: true,
                },
              ],
            },
          ],
        },
      }
    },
    lessonType() {
      if (typeof this.$store.getters['app/getCurrentCourse'] !== 'undefined') return this.$store.getters['app/getCurrentCourse'].type

      return 'lesson'
    },
    lessonTitle() {
      if (typeof this.$store.getters['app/getCurrentCourse'] !== 'undefined') return this.$store.getters['app/getCurrentCourse'].title

      return ''
    },
    lessonContent() {
      if (typeof this.$store.getters['app/getCurrentCourse'] !== 'undefined') return this.$store.getters['app/getCurrentCourse'].content

      return ''
    },
    lessonNotate() {
      return this.$store.getters['app/getCurrentCourse'].note
    },

    lesson() {
      if (typeof this.$store.getters['app/getCurrentCourse'] === 'undefined') return ''
      return this.$store.getters['app/getCurrentCourse']
    },
    currentQuiz() {
      if (typeof this.$store.getters['app/getCurrentCourse'] !== 'undefined')
        return this.$store.getters['app/getCurrentCourse'].quiz

      return ''
    },
    lessonChallengeEvents() {
      if (typeof this.$store.getters['app/getCurrentCourse'].challenge_items !== 'undefined') {
        return this.$store.getters['app/getCurrentCourse'].challenge_items.map(item => ({
          id: item.id,
          title: item.user_challenge_done ? `✔ ${item.name}` : item.name,
          date: item.date,
          content: item.content,
          is_done: item.user_challenge_done,
          color: item.user_challenge_done ? '#1FACBB' : '#3AC7D6',
        }))
      }
      return []
    },
  },
  watch: {
    lessonNotate(newNotate, oldNotate) {
      this.note = newNotate
    },
    lessonChallengeEvents(newEvents, oldEvents) {
      this.calendarOptions.events = newEvents
      this.calendarOptions.dateClick = this.handleDateClick
    },
    lesson(newLesson, oldLesson) {
      let nextLesson = false;
      let prevLesson = false;

      this.sections.forEach(section => {
        let lessons = section.lessons;
        lessons.forEach(function (lesson, index, lessons) {
          if (lesson.id === newLesson.id) {
            if (section.lessons[index + 1]) {
              nextLesson = true;
            }
            if (section.lessons[index - 1]) {
              prevLesson = true;
            }
          }
        })
      });

      this.showNextButton = nextLesson;
      this.showPrevButton = prevLesson;
    }
  },
  mounted() {
    // this.$emit('changeRouteTitle', 'nameOfYourPage')
  },
  created() {
    this.fetchCourse(this.$route.params.slug)
    // if(!this.$store.getters['app/auth/isConfirmed']) {
    //   this.$bvToast.toast(
    //       'Twój e-mail nie jest potwierdzony. zalecamy zrobienie tego jak najszybciej.', {
    //         title: 'potwierdzenie e-mail',
    //         variant: 'warning'
    //       });
    // }
  },
  methods: {
    getLessons(currentLesson){
      let nextLesson = null;
      let prevLesson = null;

      this.$http.get(`${this.$store.state.apiDomain}/api/my/course/${this.$route.params.slug}`).then(response => {
        this.course = response.data;
        this.$store.commit('app/UPDATE_CUSTOM_LAYOUT_MENU', response.data.sections)
        response.data.sections.forEach(section => {
          let lessons = section.lessons;
          lessons.forEach( function(lesson, index, lessons){
            console.log(lesson);
            console.log(currentLesson);
            if(lesson.id == currentLesson.id){
              nextLesson = (index === lessons.length - 1) ? section.lessons[index] : section.lessons[index+1];
              prevLesson = (index === 0) ? section.lessons[index] : section.lessons[index-1];
            }
          })
        })
      });

      return {
        'nextLesson': nextLesson,
        'prevLesson': prevLesson,
      };
    },
    nextLesson(currentLesson){
      let nextLessonIndex = null;
      let nextLesson = null;
      
      // let result = this.getLessons(currentLesson);
      // console.log(result);
      // this.$store.commit('app/UPDATE_CURRENT_COURSE', result.nextLesson)
      // this.isVerticalMenuActive = false;

      this.$http.get(`${this.$store.state.apiDomain}/api/my/course/${this.$route.params.slug}`).then(response => {
        this.course = response.data;

        this.$store.commit('app/UPDATE_CUSTOM_LAYOUT_MENU', response.data.sections)

        response.data.sections.forEach(section => {
          let lessons = section.lessons;
          lessons.forEach( function(lesson, index, lessons){
            if(lesson.id == currentLesson.id){
              nextLesson = (index === lessons.length - 1) ? section.lessons[index] : section.lessons[index+1];
            }
          })
        })
        return nextLesson;
      }).then(res => {
        console.log(res);
        this.$store.commit('app/UPDATE_CURRENT_COURSE', nextLesson)
        this.isVerticalMenuActive = false;
      });
    },
    prevLesson(currentLesson){
      console.log(currentLesson.id);
      // let prevtLessonIndex = null;
      let prevLesson = null;
      this.$http.get(`${this.$store.state.apiDomain}/api/my/course/${this.$route.params.slug}`).then(response => {
        this.course = response.data;

        this.$store.commit('app/UPDATE_CUSTOM_LAYOUT_MENU', response.data.sections)

        response.data.sections.forEach(section => {
          let lessons = section.lessons;
          lessons.forEach( function(lesson, index, lessons){
            if(lesson.id == currentLesson.id){
              prevLesson = (index === 0) ? section.lessons[index] : section.lessons[index-1] ;
            }
          })
        })

        this.$store.commit('app/UPDATE_CURRENT_COURSE', prevLesson)
        this.isVerticalMenuActive = false;
      }); 
    },
    handleDateClick(info) {
      info.jsEvent.preventDefault()

      this.currentEvent = {
        id: info.event._def.publicId,
        title: info.event._def.title,
        content: info.event._def.extendedProps.content,
        is_done: info.event._def.extendedProps.is_done,
        date: info.event._def.date,
        info,
      }
      this.$refs['event-modal'].show()
    },
    fetchCourse(slug) {
      this.$http.get(`${this.$store.state.apiDomain}/api/my/course/${slug}`).then(response => {

        this.course = response.data;

        this.$store.commit('app/UPDATE_CUSTOM_LAYOUT_MENU', response.data.sections)
        this.sections = response.data.sections;
        let currentLesson = null
        response.data.sections.forEach(section => {
          section.lessons.forEach(lesson => {
            if (!lesson.user_done && currentLesson === null) {
              currentLesson = lesson
            }
          })
        })

        this.$store.commit('app/UPDATE_CURRENT_COURSE', currentLesson === null ? response.data.sections[0].lessons[0] : currentLesson)
        this.showNewsletter()
      })
    },
    showNewsletter() {
      if (this.course.show_newsletter) {
        this.$refs['newsletter-modal'].show()
      }
    },
    appendArrow() {
      this.note += '→'
    },
    saveData() {
      const data = {
        lesson_id: this.$store.getters['app/getCurrentCourse'].id,
        note: this.note,
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/lesson/set-note`, data).then(response => {
        if (response.status === 200) {
          this.$bvToast.toast('Notatka zapisana pomyślnie', {
            title: 'Zapis',
            variant: 'success',
          })
        } else {
          this.$bvToast.toast('Wystąpił błąd podczas zapisu notatki', {
            title: 'Zapis',
            variant: 'danger',
          })
        }
      })
    },
    changeStatusForDone() {
      this.currentEvent.info.el.innerHTML = `${'<div class="fc-event-main">'
          + '<div class="fc-event-main-frame">'
          + '<div class="fc-event-title-container">'
          + '<div class="fc-event-title fc-sticky">'
          + '✔ '}${this.currentEvent.title.replace('✔ ', '')
          }</div>`
          + '</div>'
          + '</div>'
          + '</div>'
      this.currentEvent.info.el.style.backgroundColor = '#1FACBB'
      this.currentEvent.info.el.style.color = '#ffffff'
    },
    challengeMakeDone() {
      const data = {
        challenge_item_id: this.currentEvent.id,
        is_done: true,
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/lesson/toggle-challenge-done`, data).then(response => {
        if (response.status === 200) {
          this.$bvToast.toast('Zadanie zakończone', {
            title: 'Zapis',
            variant: 'success',
          })
          this.$refs['event-modal'].hide()
          this.changeStatusForDone()
        } else {
          this.$bvToast.toast('Wystąpił błąd podczas połączenia do serwera', {
            title: 'Zapis',
            variant: 'danger',
          })
        }
      })
    }
  },
}
</script>

<template>
  <div>
    <div class="lesson-content" v-if="lessonType == 'lesson'">
      <h3 class="mb-2">
        {{course.title}}
      </h3>
      <b-card :title="lessonTitle">
        <p v-if="!lessonContent">
          Skorzystaj z menu by wybrać lekcję lub kliknij w logo ChemMaster by wrócić do strony głównej.
        </p>
        <b-card-text v-html="lessonContent">
          {{ lessonContent }}
        </b-card-text>
        <b-button :href="lesson.pdf_url"
                  v-if="lesson.has_pdf"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  target="_blank"
        >
          Pobierz PDF
        </b-button>
        <div class="text-right pt-1">
          <b-button-group>
            <b-button
                :disabled="!showPrevButton"
                variant="outline-primary"
                @click="prevLesson(lesson)"
                style="margin-right: 3px;"
                :class="!showPrevButton ? 'disabled' : ''"
            >
              Poprzednia lekcja
            </b-button>
            <b-button
                :disabled="!showNextButton"
                variant="outline-primary"
                @click="nextLesson(lesson)"
                :class="!showNextButton ? 'disabled' : ''"
            >
              Następna lekcja
            </b-button>
          </b-button-group>
        </div>
      </b-card>
    </div>
    <b-card title="Plan treningu">
      <Trainings :key="lessonTitle" :course="course" :refillable="true"/>
    </b-card>
  </div>
</template>

<style>
img{
  max-width: 100%;
  height: auto;
}
iframe{

}
.fc-daygrid-event{
  white-space:normal!important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
// prevent copying of text
/*.lesson-content {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}*/
</style>
